import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Main from './main/Main'
import Nav from './header/Nav'
import ApproveDoc from './docs/ApproveDoc'


function App() {
  return (
    <>
      <Nav />
      <Main />
      <Routes>
        <Route path="/docs" element={<ApproveDoc />} />
      </Routes>
    </>
  )
}

export default App