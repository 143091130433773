import React from 'react';

export default function Footer() {

  function copyRight() {
    return (
      <div>
        {'Copyright © '}
        {new Date().getFullYear()}
      </div>
      
    )
  }

  return (
    <div className='su m-4 d-flex justify-content-between' >
      <span className='fs-3' >
      </span>
      <span className='fs-5' >
        {copyRight()}
      </span>
    </div>
  );
}
