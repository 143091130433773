import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardImgOverlay, CardTitle, CardImg, Button }from 'reactstrap'
import mapmkr from '../../assets/mapmarkers.png'
import wdyw from '../../assets/meme1.png'
import Footer from '../footer/Footer'

export default function Main() {
  return (
    <>
    <Card className='w-100 border border-0 d-flex justify-content-end' >
      <CardImg
        alt="Card image logo"
        src={mapmkr}
      />
        <p className='fs-4 m-3' >
          At Eat Here we are focused on solving the question, "where do you want to eat?" A question everyone faces.  Imagine, if you will, that you have the tool that will allow you to decide effortlessly where to eat. A way to randomly generate your next place to eat. You filter out what you don't want. Then you are provided with a solid choice. Simple, effective. Nothing compilcated just exactly what you need to solve the problem, "Where to eat ?".
        </p>      
    <CardImgOverlay >
      <CardTitle className='text-white mt-3 fs-1 d-flex justify-content-center' tag="h3">
        Eat Here
      </CardTitle>  
      <div className='get-started d-flex justify-content-center' >
      <Button className='btn-get-started' outline size="lg"  >
       <Link to="https://eat-here.vercel.app" >Get Started</Link> 
      </Button> 
      </div>
    </CardImgOverlay>
    </Card>
    <Card className='w-100 border border-0 d-flex justify-content-end' >
      <CardImg
        alt="Card image logo"
        src={wdyw}
      />
      <p className='fs-4 m-3' >
        Just sign up and click the <Link to="https://eat-here.vercel.app" className='su' >link</Link> sent to your email.
      </p>    
    </Card>
    <Footer/>
    </>
  )
}
