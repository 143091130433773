import React from "react";
import { CardImg, Button } from "reactstrap";
import AdBanner from "../ad space/AdBanner";
import logo from "../../assets/EHFinalLogo.svg";
import { Link } from "react-router-dom";

function Nav() {
  return (
    <div className="p-3 bg-light border border-0">
      <div className="d-flex justify-content-between">
        <CardImg
          alt="Card logo pic"
          style={{ height: "50px", width: "50px" }}
          src={logo}
        />
        <AdBanner />
        <div className="mt-1">
          <Button outline className="su ms-4 mx-3 ">
            <Link to="https://eat-here.vercel.app">Sign Up</Link>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Nav;
